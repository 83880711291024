@import 'bulma/css/bulma.css';
@import "~animate.css/animate.css";

.home-page {
  position: relative; 
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.home-page::before {
  content: "";
      background-image: url(../src/styles/images/andrej-lisakov-3A4XZUopCJA-unsplash.jpg);
      background-size: cover;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 70%;
      background-size: 100% 100%;
}

.icon-linkedin {
  color: #0072b1;
}

.project-title {
  text-decoration: underline;
  text-underline-offset: 20px;
  text-decoration-thickness: 2px;
}

.source-button {
  float: right;
}

.icon-github {
  color: #4078c0;
}

.home-logo {
  margin: 5px;
}

.name {
  color: turquoise;
}

#icons {
  color: turquoise;
}

@media (min-width: 768px) {
  .project-columns {
    height: 300px;
    width: 447px;
  }
}

@media screen and (max-width: 600px) {
  .reversed-columns {
    display: flex;
    flex-direction: column-reverse;
  }
  .logo-wrapper {
    display: flex;
    flex-wrap: wrap;
  } 
  .project-title {
    margin: 40px;
  }
}

.showme {
  display: block;
}

.hideme{
  display: none;
}

:root {
  --animate-duration: 800ms;
  --animate-delay: 0.9s;
}
